import { Injectable, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class DeepLinkService {
  constructor(
    private navigationService: NavigationService,
    private zone: NgZone,
  ) {}

  initialize(): void {
    void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const url = new URL(event.url).pathname;
        if (url) {
          void this.navigationService.navigate(url);
        }
      });
    });
  }
}
