import { CanActivateFn } from '@angular/router';
import { AppState } from '../store/app.state';
import { inject } from '@angular/core';
import { filter, firstValueFrom, switchMap } from 'rxjs';
import { UserState, UserType } from '../store/user.state';
import { ProfileState } from '../store/profile.state';
import { NavigationService } from '../services/navigation.service';

export const bookGuard: CanActivateFn = async () => {
  const appState = inject(AppState);
  const navigationService = inject(NavigationService);
  const userState = inject(UserState);
  const profileState = inject(ProfileState);

  const user = await firstValueFrom(
    appState.appReady$.pipe(
      filter(appReady => appReady),
      switchMap(() => userState.user$),
    ),
  );

  if (user.userType !== UserType.GUEST && !profileState.currentProfile$.value) {
    void navigationService.navigateRoot(profileState.profiles$.value.length > 1 ? '/profile/select' : '/profile/create', {
      state: { hideHeader: true },
    });
    return false;
  }

  return true;
};
