import { Route } from '@angular/router';
import { coreGuard } from './guards/core.guard';
import { authenticationPageGuard } from './pages/authentication/authentication.guard';
import { bookCompletePageGuard } from './pages/book-complete/book-complete.guard';
import { HomePage } from './pages/home/home.page';
import { guestGuard } from './guards/guest.guard';
import { tipsGuard } from './pages/tips/tips.guard';
import { bookGuard } from './guards/book.guard';

const readerRoutes: Route = {
  path: 'book/:uuid',
  canActivate: [bookGuard],
  children: [
    {
      path: 'details',
      loadComponent: () => import('./pages/book-details/book-details.page').then(mod => mod.BookDetailsPage),
    },
    {
      path: 'complete',
      loadComponent: () => import('./pages/book-complete/book-complete.page').then(mod => mod.BookCompletePage),
      canActivate: [bookCompletePageGuard],
    },
    {
      path: 'reader',
      children: [
        { path: '', loadComponent: () => import('./pages/book-reader/book-reader.page').then(mod => mod.BookReaderPage) },
        { path: 'font', loadComponent: () => import('./pages/book-reader/book-reader.page').then(mod => mod.BookReaderPage) },
        { path: 'table-of-contents', loadComponent: () => import('./pages/book-reader/book-reader.page').then(mod => mod.BookReaderPage) },
        { path: 'settings', loadComponent: () => import('./pages/book-reader/book-reader.page').then(mod => mod.BookReaderPage) },
      ],
    },
    { path: '**', redirectTo: '/home', pathMatch: 'full' },
  ],
};

const profileRoutes: Route = {
  path: 'profile',
  children: [
    { path: '', loadComponent: () => import('./pages/profile/profile.page').then(mod => mod.ProfilePage) },
    {
      path: 'create',
      loadComponent: () => import('./pages/profile-create/profile-create.page').then(mod => mod.ProfileCreatePage),
      canActivate: [guestGuard],
    },
    {
      path: 'select',
      loadComponent: () => import('./pages/profile-select/profile-select.page').then(mod => mod.ProfileSelectPage),
      canActivate: [guestGuard],
    },
    {
      path: 'delete',
      loadComponent: () => import('./pages/profile-delete/profile-delete.page').then(mod => mod.ProfileDeletePage),
      canActivate: [guestGuard],
    },
    {
      path: 'edit',
      loadComponent: () => import('./pages/profile-edit/profile-edit.page').then(mod => mod.ProfileEditPage),
      canActivate: [guestGuard],
    },
  ],
};

const legacyRoutes: Route[] = [
  { path: ':lang/Book/Details/:uuid', redirectTo: 'book/:uuid/details' },
  { path: ':lang/sem/:uuid/:version', redirectTo: 'book/:uuid/details' },
  { path: 'sem/:uuid/:version', redirectTo: 'book/:uuid/details' },
  { path: 'stw/:uuid/:version', redirectTo: 'book/:uuid/details' },
  { path: 'swa/:uuid/:version', redirectTo: 'book/:uuid/details' },
  { path: 'Book/Details/:uuid', redirectTo: 'book/:uuid/details' },
  { path: 'BookSmart/Library', redirectTo: 'my-library' },
  { path: 'BookSmart/Account/Login', redirectTo: 'authentication' },
];

export const routes: Route[] = [
  {
    path: '',
    canActivate: [coreGuard],
    children: [
      readerRoutes,
      profileRoutes,
      ...legacyRoutes,
      { path: 'language', loadComponent: () => import('./pages/language/language.page').then(mod => mod.LanguagePage) },
      { path: 'library-code', loadComponent: () => import('./pages/library-code/library-code.page').then(mod => mod.LibraryCodePage) },
      {
        path: 'delete-account',
        loadComponent: () => import('./pages/delete-account/delete-account.page').then(mod => mod.DeleteAccountPage),
        canActivate: [guestGuard],
      },
      { path: 'how-to-use', loadComponent: () => import('./pages/how-to-use/how-to-use.page').then(mod => mod.HowToUsePage) },
      { path: 'book-error', loadComponent: () => import('./pages/book-error/book-error.page').then(mod => mod.BookErrorPage) },
      {
        path: 'authentication',
        loadComponent: () => import('./pages/authentication/authentication.page').then(mod => mod.AuthenticationPage),
        canActivate: [authenticationPageGuard],
      },
      {
        path: 'reset-password',
        loadComponent: () => import('./pages/authentication/authentication.page').then(mod => mod.AuthenticationPage),
        canActivate: [authenticationPageGuard],
      },
      {
        path: 'confirmation-email',
        loadComponent: () => import('./pages/authentication/authentication.page').then(mod => mod.AuthenticationPage),
        canActivate: [authenticationPageGuard],
      },
      { path: 'shelf/:type', loadComponent: () => import('./pages/shelf/shelf.page').then(mod => mod.ShelfPage) },
      {
        path: 'shelf/category/:categoryId',
        loadComponent: () => import('./pages/shelf/shelf.page').then(mod => mod.ShelfPage),
      },
      {
        path: 'tips',
        loadComponent: () => import('./pages/tips/tips.page').then(mod => mod.TipsPage),
        canActivate: [tipsGuard],
      },
      {
        path: 'tips/category/:categoryId',
        loadComponent: () => import('./pages/tips-category/tips-category.page').then(mod => mod.TipsCategoryPage),
        canActivate: [tipsGuard],
      },
      {
        path: '',
        loadComponent: () => import('./components/tabs/tabs.component').then(mod => mod.TabsComponent),
        children: [
          { path: '', redirectTo: 'home' },
          { path: 'home', component: HomePage }, // Lazy load everything except the home page
          { path: 'menu', loadComponent: () => import('./pages/mobile-menu/mobile-menu.page').then(mod => mod.MobileMenuPage) },
          { path: 'my-library', loadComponent: () => import('./pages/my-library/my-library.page').then(mod => mod.MyLibraryPage) },
        ],
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
];
