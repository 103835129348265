import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { AnalyticsEventType, ModalViewEvents } from '../../../../services/analytics/analytics.model';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { ModalController } from '@ionic/angular/standalone';
import { ButtonComponent } from '../../../../components/button/button.component';
import { BookShelfService } from '../../../../services/book-shelf.service';
import { LanguageService } from '../../../../services/language.service';
import { NavigationService } from '../../../../services/navigation.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-select-category-mobile-modal',
  templateUrl: './select-category-mobile-modal.page.html',
  styleUrls: ['./select-category-mobile-modal.page.scss'],
  standalone: true,
  imports: [TranslateModule, AsyncPipe, ButtonComponent],
})
export class SelectCategoryMobileModalPage {
  constructor(
    public bookShelfService: BookShelfService,
    public languageService: LanguageService,
    private analyticsService: AnalyticsService,
    private modalController: ModalController,
    private navigationService: NavigationService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.SelectCategoryModal });
  }

  goToShelfPage(categoryId: number): void {
    void this.navigationService.navigate('shelf/category/' + categoryId);
    void this.modalController.dismiss();
  }
}
