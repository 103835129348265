import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppService } from './app.service';
import { NavigationService } from './navigation.service';

interface CustomAUTWindow {
  cypressNavigateByUrl: (url: string) => void;
  triggerWebUpdateModal: (url: string) => void;
}

@Injectable({ providedIn: 'root' })
export class DeveloperToolsService {
  constructor(
    private navigationService: NavigationService,
    private ngZone: NgZone,
    private appService: AppService,
  ) {}

  initialize(): void {
    if (environment.envName === 'prod') {
      return;
    }

    (window as unknown as CustomAUTWindow).cypressNavigateByUrl = (url: string): void => this.cypressNavigateByUrl(url);
    (window as unknown as CustomAUTWindow).triggerWebUpdateModal = (url: string): void => this.triggerWebUpdateModal(url);
  }

  cypressNavigateByUrl(url: string): void {
    this.ngZone.run(() => {
      void this.navigationService.navigate(url);
    });
  }

  triggerWebUpdateModal(url: string): void {
    void this.appService.updateApp(url);
  }
}
