import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShelfType } from '../../services/book-shelf.service';
import { fadeInOut } from '../../utils/animations';
import { HeaderComponent } from '../../components/header/header.component';
import { SearchComponent } from './component/search/search.component';
import { BookShelfComponent } from '../../components/book-shelf/book-shelf.component';
import { BookCategoriesShelvesComponent } from '../../components/book-categories-shelves/book-categories-shelves.component';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { AsyncPipe } from '@angular/common';
import { ProfileState } from '../../store/profile.state';
import { TipsComponent } from './component/tips/tips.component';
import { LanguageState } from '../../store/language.state';
import { SupportedLanguage } from '../../services/language.service';
import { combineLatest, map, Observable } from 'rxjs';
import { DefaultProjectState } from '../../store/default-project.state';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  animations: [fadeInOut],
  standalone: true,
  imports: [HeaderComponent, SearchComponent, BookShelfComponent, BookCategoriesShelvesComponent, IonContent, AsyncPipe, TipsComponent, IonButton],
})
export class HomePage {
  constructor(
    public profileState: ProfileState,
    private languageState: LanguageState,
    private defaultProjectState: DefaultProjectState,
  ) {}

  isSearchFocused = false;

  protected readonly shelfType = ShelfType;

  shouldDisplayTips$: Observable<boolean> = combineLatest([
    this.languageState.language$,
    this.profileState.currentProfile$,
    this.defaultProjectState.defaultProject$,
  ]).pipe(
    map(
      ([language, currentProfile, defaultProject]) =>
        currentProfile?.isFamilyProfile === true &&
        [SupportedLanguage.English, SupportedLanguage.Kiswahili].includes(language.selected) &&
        defaultProject.vroomActivitiesEnabled,
    ),
  );

  searchFocused(focused: boolean): void {
    this.isSearchFocused = focused;
  }
}
