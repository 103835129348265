import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ToastService } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Feature, LoggerService } from '../services/logger.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private loggerService: LoggerService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        // We only do this for API's that contains the booksmart token
        if (error instanceof HttpErrorResponse && error.status !== 401 && error.status !== 403 && error.status !== 404) {
          // TODO Reactivate this toast after fixing the issue with 400 errors B20CP-602
          // void this.toastService.present({
          //   message: this.translateService.instant('PWA_appIssues_error_serverError'),
          //   type: ToastType.Negative,
          //   displayClose: false,
          // });
        }

        if (error.status && error.status !== 0) {
          if (!this.shouldIgnoreHttpError(request, error.status)) {
            this.loggerService.error('ErrorInterceptor::intercept - Request failed', {
              feature: Feature.API,
              context: 'Status: ' + error.status + ', url:' + error.url + ', message: ' + error.message,
            });
          }
        }

        return throwError(() => error);
      }),
    );
  }

  private shouldIgnoreHttpError(request: HttpRequest<unknown>, errorStatus: number): boolean {
    if (errorStatus === 404) {
      // Library code check
      if (request.method === 'GET' && request.url.startsWith(environment.booksBaseUrl + '/featured/active')) {
        return true;
      } else if (request.method === 'GET' && request.url.startsWith(environment.projectBaseUrl)) {
        return true;
      }
    }

    if (errorStatus === 400) {
      // Email check, email not valid
      if (request.method === 'POST' && request.url.startsWith(environment.usersBaseUrl + '/auth/email/exists')) {
        return true;
      } else if (request.method === 'POST' && request.url.startsWith(environment.usersBaseUrl + '/auth/email/login')) {
        return true;
      } else if (request.method === 'POST' && request.url.startsWith(environment.usersBaseUrl + '/auth/email/signup')) {
        return true;
        // Happen if the user is considered as a bot by the backend
      } else if (request.method === 'GET' && request.url.startsWith(environment.usersBaseUrl + '/auth/guest-user')) {
        return true;
        // Apple - email already exist
      } else if (request.method === 'POST' && request.url.startsWith(environment.usersBaseUrl + '/auth/apple')) {
        return true;
      } // Google - email already exist
    } else if (request.method === 'POST' && request.url.startsWith(environment.usersBaseUrl + '/auth/google')) {
      return true;
    }
    return false;
  }
}
