import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CanActivateFn } from '@angular/router';
import { BookService } from '../../services/book-service';
import { NavigationService } from '../../services/navigation.service';

export const bookCompletePageGuard: CanActivateFn = async () => {
  const bookService = inject(BookService);
  const navigationService = inject(NavigationService);

  const bookComplete = await firstValueFrom(bookService.bookComplete$);

  if (!bookComplete) {
    void navigationService.navigate('');
    return false;
  }

  return true;
};
