import { inject } from '@angular/core';
import { firstValueFrom, switchMap, take } from 'rxjs';
import { CanActivateFn } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { NavigationService } from '../../services/navigation.service';

export const tipsGuard: CanActivateFn = async () => {
  const navigationService = inject(NavigationService);
  const profileState = inject(ProfileState);

  const currentProfile = await firstValueFrom(
    profileState.currentProfile$.pipe(
      take(1),
      switchMap(() => profileState.currentProfile$),
    ),
  );

  if (!currentProfile?.isFamilyProfile) {
    void navigationService.navigate('home');
    return false;
  }

  return true;
};
