import { CanActivateFn } from '@angular/router';
import { AppState } from '../store/app.state';
import { inject } from '@angular/core';
import { BookService } from '../services/book-service';
import { UserState, UserType } from '../store/user.state';
import { ProfileState } from '../store/profile.state';
import { FlagsState } from '../store/flags.state';
import { ModalController } from '@ionic/angular/standalone';
import { combineLatest, filter, firstValueFrom } from 'rxjs';
import { LanguageSelectionModalPage } from '../modals/language-selection/language-selection-modal.page';
import { NavigationService } from '../services/navigation.service';
import type { ComponentRef } from '@ionic/core';

let redirectedToProfileSelection = false;
let languageSelectionModalDisplayed = false;

export const coreGuard: CanActivateFn = async () => {
  const appState = inject(AppState);
  const navigationService = inject(NavigationService);
  const bookService = inject(BookService);
  const userState = inject(UserState);
  const profileState = inject(ProfileState);
  const flagsState = inject(FlagsState);
  const modalController = inject(ModalController);

  await firstValueFrom(appState.appReady$.pipe(filter(appReady => appReady)));

  const [profiles, user] = await firstValueFrom(combineLatest([profileState.profiles$, userState.user$]));

  // If the current route is not a book route, we need to clear the book saved in the memory
  if (window.location.pathname.split('/')[1] !== 'book') {
    bookService.clear();
  }

  // If the user is logged in, we must redirect to the profile selection page except for email confirmation modal
  if (user.userType !== UserType.GUEST && !redirectedToProfileSelection && routeShouldRedirecToProfile(userState.user$.value.id)) {
    if (profiles.length > 1) {
      void navigationService.navigateRoot('profile/select', {
        state: {
          hideHeader: true,
          firstOpening: true,
          redirectTo: window.location.pathname,
        },
      });
    } else {
      void navigationService.navigateRoot('/profile/create', { state: { hideHeader: true } });
    }

    redirectedToProfileSelection = true;

    showOptionalLanguageSelectionModal(flagsState.flags$.value.languageSelectionModalPrompted, modalController);

    return false;
  }

  redirectedToProfileSelection = true;

  showOptionalLanguageSelectionModal(flagsState.flags$.value.languageSelectionModalPrompted, modalController);

  return true;
};

const showOptionalLanguageSelectionModal = (languageSelectionModalPrompted: boolean, modalController: ModalController): void => {
  if (languageSelectionModalDisplayed) {
    return;
  }

  languageSelectionModalDisplayed = true;
  // We display this modal only the first time the user access the app & if the device language does not match
  // With one of the language available in the app.
  if (!languageSelectionModalPrompted) {
    void modalController
      .create({
        canDismiss: (_, role?: string) => new Promise<boolean>(resolve => resolve(role === 'close')),
        component: LanguageSelectionModalPage as ComponentRef,
        animated: false,
        cssClass: 'wr-modal language-selection fullscreen',
      })
      .then(l => l.present());

    redirectedToProfileSelection = true;
  }
};

const routeShouldRedirecToProfile = (userId: string): boolean => {
  const params = new URL(window.location.toString()).searchParams;
  const jwt = params.get('jwt');

  if (window.location.href.includes('/confirmation-email') && jwt && matchJwt(jwt, userId)) {
    return false;
  } else if (window.location.href.includes('/reset-password')) {
    return false;
  }

  return true;
};

const matchJwt = (urlJwt: string, storedUserId: string): boolean => {
  try {
    const urlUserId = JSON.parse(atob(urlJwt.split('.')[1]))?.userId;

    if (!storedUserId) {
      return false;
    }

    return urlUserId === storedUserId;
  } catch (error) {
    return false;
  }
};
